.bullets-container {
    position: fixed;
    margin-left: auto;
    text-align: center;
    right: 0px;
    margin-right: 0;
    top: 44%;
    z-index: 1000000;
}
@media screen and (max-width:640px) {
    .bullets-container {
        right: -2%;
    }
}